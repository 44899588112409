import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import Carte from "../components/Carte"

const EtalonPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title} keywords={fields.keywords} description={fields.description}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb className="carte-banner" textShadow={true} slug={fields.slug} title={fields.title} bgImage={bgImage} bgOffset="-200" bgPadding="8em" />
          <Carte 
            subtitle={fields.subtitle} 
            content={data.page.htmlAst}
            data={fields} 
            locale={localeData.locale}
            />
      </Layout>
    );
};
export default withI18next()(EtalonPage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      htmlAst
      frontmatter {
        slug
        title
        keywords
        subtitle
        description
        contentDescription
        closingText
        questionTitle
        questionEmail
        questionPhone
        questionDescription
        carte {
          categories {
            name
            icon
            images {
              src {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              breakpointName
              breakpointModifier
              height
            }
            items {
              name
              price
              descriptions {
                description
              }
            }
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "bg/etalon.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`